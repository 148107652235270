export default [
  {
    header: 'COORDENAÇÃO',
    resource: 'usuários',
    action: 'menu',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [

      {
        title: 'Classes',
        route: 'classes',
        icon: 'TagIcon',
        resource: 'classes',
        action: 'menu',
      },

    ],
  },

  {
    title: 'Empresas',
    route: 'empresas',
    icon: 'HomeIcon',
    resource: 'empresas',
    action: 'menu',

  },
  {
    title: 'Usuários',
    route: 'usuários',
    icon: 'UsersIcon',
    resource: 'usuários',
    action: 'menu',

  },

]
